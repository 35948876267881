import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { getCita, getCitas, unactiveCita } from '../../../actions/citas';
import { activeUsuario, startLoadingClientesEmpresa , startSaveUsuario, startUploading, unactiveUsuario, usuarioStartDelete } from '../../../actions/usuarios';
import { activeServicio } from '../../../actions/servicios';
import { activeSucursal } from '../../../actions/sucursales';
import { activeProfesional } from '../../../actions/profesionales';

import { ArrowBackIcon, AvatarIcon, CommentsIcon, EnviarIcon, ServiciosIcon, TrashIcon, UltimaReservaIcon, ListIcon } from '../../../assets/svg/iconos';
import useClickOutside from '../../../hooks/useClickOutside';
import { DelaySelect } from '../../profesionales/components/DelaySelect';
import { DelaySelectValue } from '../../profesionales/components/DelaySelectValue';
import { ModalReserva } from '../../reservas/components/ModalReserva';
import { BonoImporte } from './BonoImporte';
import { BonoServicio } from './BonoServicio';
import { Comentarios } from './Comentarios';
import { UltimaReserva } from './UltimaReserva';
import { ClienteSaldos } from './ClienteSaldos';
import { newCliente } from '../../../helpers/helpers';
import { ModalEliminarCliente } from './modales/ModalEliminarCliente';
import { ModalDeleteCita } from '../../reservas/components/ModalDeleteCita';
import { YlmModalTwoButtons } from '../../../components/main/YlmModalTwoButtons';
import { isEmail } from '../../../helpers/forms';
import { FechaNac } from './FechaNac';

import { startRegisterBusiness } from '../../../actions/auth';
import { openModalBottom } from '../../../actions/ui';
import { fetchSinToken } from '../../../helpers/fetch';

export const Cliente = ({ history }) => {

  const dispatch = useDispatch();
  const { empresa } = useSelector(state => state.auth);
  const { colores, miniLateral } = useSelector(state => state.ui);
  let citasHistoria = useSelector(state => state.citasHistoria);
  let { active: activaCita } = useSelector(state => state.citas);
  const [activeCita, setActiveCita] = useState(activaCita);
  //const { activeCita, setActiveCita } = useState({});
  let { active: usuarioActive } = useSelector(state => state.usuarios);
  const [estadoCita, setEstadoCita] = useState("");

  const usuarios = useSelector(state => state.usuarios);

  const [objCliente, setObjCliente] = useState({});
  const [saveCliente, setSaveCliente] = useState(false);
  const [iniciado, setIniciado] = useState(false);
  const [cambiado, setCambiado] = useState(false);

  const [activeModalDeleteCita, setActiveModalDeleteCita] = useState(false);
  const [activeModalReserva, setActiveModalReserva] = useState(false);
  const [editCita, setEditCita] = useState(false);

  const [editMode, setEditMode] = useState(true);
  const [selectedDate, setSelectedDate] = useState(moment());
  
  const [activeTab, setActiveTab] = useState(history.location.state.activeTab || 'ultima');
  const [currentScreen] = useState((history.location.state.usuario) ? 'edit' : 'new');
  const [objErrores, setObjErrores] = useState({});
  
  const popover = useRef();
  const [, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  const [modalEliminarClienteOpened, setModalEliminarClienteOpened] = useState(false);
  const [dataModalEliminar, setDataModalEliminar] = useState({
    title: '¿Eliminar profesional?',
    text: [],
    buttonText: 'Eliminar',
    button2Text: 'Cancelar'
  });
  const [dataModal, setDataModal] = useState({
    title: '¿Modificar cita?',
    text: ['¿Estás seguro de modificar esta cita?'],
    buttonText: 'Estoy seguro',
    button2Text: 'Cancelar'
  });

  const [modalResponse, setModalResponse] = useState(0);
  const [modalWaiting, setModalWaiting] = useState({});
  const [modalTwoButtonsOpened, setModalTwoButtonsOpened] = useState(false);

  const [imageUrl, setImageUrl] = useState('');
  const [file, setFile] = useState(null);
  const [clienteImage, setClienteImage] = useState(null);

  const objClienteRef = useRef(objCliente);
  const usuarioActiveRef = useRef(usuarioActive);
  const activeCitaRef = useRef(activeCita);

  useEffect(() => {

    if (history.location.state.usuario?._id) {
		usuarioActiveRef.current = { _id: history.location.state.usuario._id, ...history.location.state.usuario}; 
       	dispatch(activeUsuario(history.location.state.usuario._id, history.location.state.usuario));
    }
    return () => {
      dispatch(unactiveUsuario());
      dispatch(unactiveCita());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log( 'usuarioActive', usuarioActive );
  //   console.log( 'objCliente', objCliente );
  // }, [usuarioActive, objCliente]);

  /* useEffect(() => {
    console.log( currentScreen );
  }, [currentScreen]) */


  useEffect(() => {

    if(usuarioActiveRef.current && !saveCliente) usuarioActive = usuarioActiveRef.current;

    if (usuarioActive) {
      setObjCliente(usuarioActive);
      setSaveCliente(false);
      setIniciado(true);

      if (usuarioActive._id && usuarioActive.img && usuarioActive.img.length > 0){
         getImagenPrincipal(`${usuarioActive.img[0]}`);
      }

      if (usuarioActive?.ultimaReserva && usuarioActive?.ultimaReserva._id !== '333') {
        dispatch(getCita(usuarioActive?.ultimaReserva._id, empresa));
        dispatch(getCitas(usuarioActive._id, empresa));
      }
    } else {
      // setEditMode( true )
      const nuevo = newCliente(empresa);
      //setCurrentProfesional( nuevo );
      setObjCliente(nuevo);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuarioActive]);

  useEffect(() => {
    if (usuarioActive) {
      const clienteOriginal = { ...usuarioActive };
      if (iniciado) {
        let igual = true;
        for (const propiedad in clienteOriginal) {
          //console.log( `${nEmpresa[propiedad]} !== ${objEmpresa[propiedad]}`)
          if (clienteOriginal[propiedad] !== objCliente[propiedad]) {
            igual = false;
            //console.log(`Cambio en ${propiedad}`);
          }
        }
        setCambiado(!igual);
      }
    }
    if (clienteImage) {
      setCambiado(true);
    }
    objClienteRef.current = objCliente;

  }, [iniciado, usuarioActive, objCliente, clienteImage]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      /* if( !usuarioSaved ){
        return false;
      } */
    });

    return () => {
      unblock();
    }
  }, [history]);

  useEffect(() => {
     //if(activeCitaRef.current) setActiveCita(activeCitaRef.current);
     if(activeCita){
        activeCitaRef.current = activeCita;
        if(usuarioActive){
            dispatch( activeServicio( activeCita.servicios[0]._id, activeCita.servicios[0] ));
            dispatch( activeSucursal( activeCita.sucursal._id, activeCita.sucursal ));
            dispatch( activeProfesional( activeCita.profesional._id, activeCita.profesional ));
        }
     }
  }, [activeCita]);

  useEffect(() => {
    if (modalResponse === 100) {
        console.log("Ejecutandose modalResponse...");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [modalResponse])

  const onFormChange = (e) => {
    dataChange(e.target.name, e.target.value);
  }

  const dataChange = (name, value) => {
	
    if (editMode) {		
      setObjCliente({
        ...objCliente,
        [name]: value
      });
    } else {
      dispatch(openModalBottom({ text: ['Debes activar la edición para poder realizar cambios'], timeOut: 3 }));
    }
  }

  const createCita = () => {
    setActiveModalReserva(!activeModalReserva);
  }

  const editarCita = (cita) => {  		
	  setEditCita(true);
    //setActiveCita(cita);
    activeCitaRef.current = cita;
    setActiveModalReserva(!activeModalReserva);
  }

  const cancelClick = (e) => {
    if (!cambiado) {
      history.goBack();
    }
    /* if( usuarioSaved ){
      history.goBack();
    } */
  }

  const guardar = () => {
    let newObj = {
      ...objCliente,
      _id: objCliente._id
    }
    if (clienteImage) {
      let previa = '';
      if (objCliente.img.length > 0) {
        previa = objCliente.img[0];
      }
      const imgName = `${objCliente._id}-${new Date().getTime()}`;
      const archivo = file;
      const nombreCortado = archivo.name.split('.');
      const extension = nombreCortado[nombreCortado.length - 1];
      const nombreCompleto = `${imgName}.${extension}`;

      newObj = { ...newObj, img: [nombreCompleto] };

      dispatch(startUploading(file, newObj, nombreCompleto, previa));
    }
    let correcto = true;
    const newObjErrores = {};
    if (objCliente.nombre.trim() === '') {
      correcto = false;
      newObjErrores.nombre = 'Debe escribir un nombre';
    }
    if (!isEmail(objCliente.email)) {
      correcto = false;
      newObjErrores.email = 'Debe escribir un email correcto';
    }

    if (objCliente.telefono.trim() === '') {
      correcto = false;
      newObjErrores.telefono = 'Debe escribir un teléfono';
    }

    //console.log( 'newObj',newObj );
    //console.log('Correcto:', correcto );
    //console.log('Errores:', newObjErrores );
    setObjErrores(newObjErrores);
    if (correcto) {
      if (currentScreen === 'new') {
        dispatch(startRegisterBusiness(newObj));
        history.push({ pathname: `/clientes` })
      } else {
        dispatch(startSaveUsuario(newObj))
          .then(() => {
              dispatch(activeUsuario(newObj._id, newObj));
              
              setActiveTab('clientesaldos')
          });
          setObjCliente(newObj);
          setSaveCliente(true);
      }
    } else {
      console.log(newObjErrores);
    }
  }
  const cancelar = () => {
    if (currentScreen !== 'new') {
      setEditMode(true);
      setClienteImage(null);
      setFile(null);
      setObjCliente(usuarioActive);
    } else {
      history.goBack();
    }
  }

  const setDelay = (type, value) => {
    if (iniciado) {
      // console.log( type, value );
      setObjCliente({
        ...objCliente,
        delayType: type,
        delay: value
      });
    }
  }

  const deleteClienteModal = () => {
    setDataModalEliminar({
      ...dataModalEliminar,
      text: [`¿Estás seguro de que deseas eliminar a ${objCliente.nombre}?`]
    })
    setModalEliminarClienteOpened(true);
  };

  const deleteClienteResponse = (value) => {
    if (value === 1) {
      console.log('value = 1');
      let selectedId = objCliente._id;

      if (!selectedId) {
        if (usuarioActive._id) {
          selectedId = usuarioActive._id;
        }
      };

      console.log('Borrar id:', selectedId);
      dispatch(usuarioStartDelete(selectedId, empresa));
      history.push({ pathname: `/clientes` })
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    /* console.log( 'onDrop' );
    console.log( 'objServicio', objServicio );
    console.log( acceptedFiles );

    dispatch( startUploading( acceptedFiles[0], objServicio._id, objServicio.empresa )); */
    const file = acceptedFiles[0];
    setFile(file);
    setClienteImage(URL.createObjectURL(file));

    //dispatch( startUploading( file, objProfesional._id, objProfesional.empresa ) );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objCliente]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    onDrop
  });

  const getImagenPrincipal = async (nombre) => {
    const imagen = await fetchSinToken(`imagen/user/${nombre}`);
    setImageUrl(imagen);
  }

  return (
    <>
      <div
        className='cliente'
        style={{
          '--colorPrincipal': colores.colorPrincipal,
          '--colorSecundario': colores.colorSecundario,
          '--color3': colores.color3,
          '--color4': colores.color4,
          '--color5': colores.color5,
          '--color6': colores.color6,
          '--color7': colores.color7,
        }}
      >
        <div className='cliente-wrapper'>
          <div className='single-cliente'>
            <div className={`header ${!miniLateral && 'compress'}`}>
              <div
                className='nav-back'
                onClick={(e) => cancelClick(e)}
              >
                <ArrowBackIcon color={colores.colorSecundario} />
                <p style={{ color: colores.colorSecundario }}>Clientes</p>
              </div>
              <div className='botonera'>
                {/* {
                            (!cambiado && currentScreen !== 'new') &&
                            <button
                                className='btn-mini btn-editar'
                                style={{ backgroundColor: editMode ? colores.colorPrincipal : colores.color6 }}
                                onClick={ () => setEditMode( !editMode ) }
                            >
                                <EditarIcon color={ editMode ? colores.color6 : colores.colorPrincipal } />
                            </button>
                        } */}
                {
                  (!cambiado && currentScreen !== 'new') &&
                  <button
                    className='btn-mini btn-eliminar'
                    style={{ backgroundColor: colores.color6 }}
                    onClick={deleteClienteModal}
                  >
                    <TrashIcon color={colores.colorPrincipal} />
                  </button>
                }
                {
                  (cambiado || currentScreen === 'new') &&
                  <button
                    className='btn btn-crear'
                    style={{ backgroundColor: colores.colorPrincipal }}
                    onClick={guardar}
                  >
                    {`${currentScreen === 'new' ? 'Crear' : 'Guardar'}`}
                  </button>
                }
                {
                  (cambiado || currentScreen === 'new') &&
                  <button
                    className='btn btn-cancelar'
                    style={{ color: colores.colorPrincipal, backgroundColor: colores.color6 }}
                    onClick={cancelar}
                  >
                    Cancelar
                  </button>
                }
              </div>
            </div>
            <div className='cliente-body'>
              <h1>{objClienteRef?.nombre} {objClienteRef?.apellidos}</h1>
              <div className='user-main'>
                <div
                  className='cliente-col col-1'
                  style={{ backgroundColor: colores.color6 }}
                >
                  <div
                    {...getRootProps({ className: `dropzone avatar` })}
                    style={{
                      backgroundImage: `url(${clienteImage ? clienteImage : imageUrl.url + '?' + uuidv4()})`
                    }}
                  >
                    <input {...getInputProps({ className: `${(!clienteImage && !imageUrl) ? 'no-image' : ''}` })} />
                    {
                      (!clienteImage && !imageUrl) &&
                      <>
                        <AvatarIcon />
                        <div className='avatar-bg'>+</div>
                      </>
                    }
                  </div>
                  {/* <div className='avatar'>
                            <AvatarIcon />
                        </div> */}
                  <div className='data-group'>
                    <div className='data'>
                      <div className='name'>
                        <input
                          style={{
                            backgroundColor: editMode ? '#ffffff' : 'transparent',
                            border: `${objErrores.nombre ? '2px solid ' + colores.color5 : 'none'}`
                          }}
                          autoComplete='off'
                          type="text"
                          placeholder='Nombre'
                          name='nombre'
                          value={objCliente.nombre || ''}
                          onChange={(e) => onFormChange(e)}
                        />
                      </div>
                      <div className='name'>
                        <input
                          style={{
                            backgroundColor: editMode ? '#ffffff' : 'transparent',
                            border: `${objErrores.nombre ? '2px solid ' + colores.color5 : 'none'}`
                          }}
                          autoComplete='off'
                          type="text"
                          placeholder='Apellidos'
                          name='apellidos'
                          value={objCliente.apellidos || ''}
                          onChange={(e) => onFormChange(e)}
                        />
                      </div>
                      <div className='delays'>
                        <DelaySelect
                          delayType={objCliente.delayType}
                          delay={objCliente.delay}
                          setDelay={setDelay}
                          editMode={editMode}
                        />
                        <DelaySelectValue
                          delayType={objCliente.delayType}
                          delay={objCliente.delay}
                          setDelay={setDelay}
                          editMode={editMode}
                        />
                      </div>
                    </div>
                    <div className='contact-data'>
                      <div className='contact-input phone'>
                        <input
                          style={{
                            backgroundColor: `${editMode ? '#FFFFFF' : colores.color6}`,
                            border: `${objErrores.telefono ? '2px solid ' + colores.color5 : 'none'}`
                          }}
                          autoComplete='off'
                          type={`number`}
                          placeholder='Teléfono'
                          name='telefono'
                          value={objCliente.telefono || ''}
                          onChange={(e) => onFormChange(e)}
                        />
                      </div>
                      <div className='contact-input email'>
                        <input
                          style={{
                            backgroundColor: `${editMode ? 'rgba(239,239,239, 0.3)' : colores.color6}`,
                            border: `${objErrores.email ? '2px solid ' + colores.color5 : 'none'}`
                          }}
                          autoComplete='off'
                          type={`email`}
                          placeholder='Email'
                          disabled={currentScreen === 'edit'}
                          name='email'
                          value={objCliente.email || ''}
                          onChange={(e) => onFormChange(e)}
                        />
                      </div>
                      <FechaNac
                        editMode={editMode}
                        objCliente={objCliente}
                        dataChange={dataChange}
                      />
                    </div>
                    <div className='contact-data'>
                      <label>Saldo Monedero: </label>
                      <div className='contact-input'>
                          <input
                              style={{
                                backgroundColor: editMode ? '#ffffff' : 'transparent',
                                border: `${objErrores.saldoMonedero ? '2px solid ' + colores.color5 : 'none'}`,
                                width: '50px'
                              }}
                              type="text"
                              name="saldoMonedero"
                              placeholder={`Saldo Monedero`}
                              value={ objCliente.saldoMonedero || 0}
                              onChange={ (e) => onFormChange(e) }
                          />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='cliente-col col-2'>
                  <div
                    className='col-item promos'
                    style={{ backgroundColor: colores.color6 }}
                  >
                    <div className='select'>
                      <p>Enviar promo</p>
                    </div>
                    <EnviarIcon />
                  </div>
                  <div
                    className='col-item pago'
                    style={{ backgroundColor: colores.color6 }}
                  >
                    <div className='toggle-text'>
                      <p>Pago por adelantado</p>
                    </div>
                    <div
                      className={`ylm-toggle ${objCliente.pagoAdelantado && 'active'}`}
                      onClick={() => dataChange('pagoAdelantado', !objCliente.pagoAdelantado)}
                    >
                      <div
                        className='toggle-chevron'
                      >
                        <div
                          className={`toggle-bg`}
                          style={{ backgroundColor: objCliente.pagoAdelantado ? '#FFFFFF' : '#C9C5C5' }}
                        >
                          <span
                            className={`toggle-item`}
                            style={{ backgroundColor: objCliente.pagoAdelantado ? colores.colorPrincipal : '#7B7B7B' }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
              <div className='user-tabs'>
                <div
                  className={`user-tab ${activeTab === 'ultima' && 'active'}`}
                  style={{
                    backgroundColor: activeTab === 'ultima' ? colores.colorPrincipal : colores.color6,
                    color: activeTab === 'ultima' ? 'white' : colores.colorPrincipal
                  }}
                  onClick={() => setActiveTab('ultima')}
                >
                  <span className='tab-icon'>
                    <UltimaReservaIcon color={activeTab === 'ultima' ? 'white' : colores.colorPrincipal} />
                  </span>
                  <p>Última reserva</p>
                </div>
                <div
                  className={`user-tab ${activeTab === 'comentarios' && 'active'}`}
                  style={{
                    backgroundColor: activeTab === 'comentarios' ? colores.colorPrincipal : colores.color6,
                    color: activeTab === 'comentarios' ? 'white' : colores.colorPrincipal
                  }}
                  onClick={() => setActiveTab('comentarios')}
                >
                  <span className='tab-icon'>
                    <CommentsIcon color={activeTab === 'comentarios' ? 'white' : colores.colorPrincipal} />
                  </span>
                  <p>Comentarios</p>
                </div>
                <div
                  className={`user-tab ${activeTab === 'servicio' && 'active'}`}
                  style={{
                    backgroundColor: activeTab === 'servicio' ? colores.colorPrincipal : colores.color6,
                    color: activeTab === 'servicio' ? 'white' : colores.colorPrincipal,
                    userSelect: 'none',
                    cursor: "unset",
                    textDecoration: 'none',
                    opacity: "0.6"
                  }}
                // onClick={() => setActiveTab('servicio')}
                >
                  <span className='tab-icon'>
                    <ServiciosIcon color={activeTab === 'servicio' ? 'white' : colores.colorPrincipal} />
                  </span>
                  <p>Bono servicio</p>
                </div>
                <div
                  className={`user-tab ${activeTab === 'importe' && 'active'}`}
                  style={{
                    backgroundColor: activeTab === 'importe' ? colores.colorPrincipal : colores.color6,
                    color: activeTab === 'importe' ? 'white' : colores.colorPrincipal,
                    userSelect: 'none',
                    cursor: "unset",
                    textDecoration: 'none',
                    opacity: "0.6"
                  }}
                // onClick= { () => setActiveTab('importe') }
                >
                  <span className='tab-icon'>
                    <ServiciosIcon color={activeTab === 'importe' ? 'white' : colores.colorPrincipal} />
                  </span>
                  <p>Bono importe</p>
                </div>
                <div
                    className={`user-tab ${activeTab === 'clientesaldos' && 'active'}`}
                    style={{
                      backgroundColor: activeTab === 'clientesaldos' ? colores.colorPrincipal : colores.color6,
                      color: activeTab === 'clientesaldos' ? 'white' : colores.colorPrincipal
                    }}
                    onClick={() => setActiveTab('clientesaldos')}
                  >
                    <span className='tab-icon'>
                      <ListIcon color={activeTab === 'clientesaldos' ? 'white' : colores.colorPrincipal} />
                    </span>
                    <p>Historial Saldos</p>
                  </div>
                </div>
              <div className='user-secondary'>
                {
                  activeTab === 'ultima' &&
                  <UltimaReserva
                    cita={activeCita}
                    citasHistoria={citasHistoria}
                    createCita={createCita}
                    editCita={editarCita}
                    setEditCita={setEditCita}
                    editMode={editMode}
                    setActiveCita={setActiveCita}
                  />
                }
                {
                  activeTab === 'comentarios' &&
                  <Comentarios
                    objCliente={objCliente}
                    dataChange={dataChange}
                    editMode={editMode}
                  />
                }
                {
                  activeTab === 'servicio' &&
                  <BonoServicio
                    editMode={editMode}
                  />
                }
                {
                  activeTab === 'importe' &&
                  <BonoImporte
                    editMode={editMode}
                  />
                }
                {
                  activeTab === 'clientesaldos' &&
                  <ClienteSaldos
                    objCliente={objCliente}
                    saveCliente={saveCliente}
                  />
                }
              </div>
            </div>
          </div>
          <ModalReserva
		  	    history={history}
            citaActive={activeCita}
            setEstadoCita={setEstadoCita}
            activeModalReserva={activeModalReserva}
            setActiveModalReserva={setActiveModalReserva}
            setActiveModalDeleteCita={setActiveModalDeleteCita}
            editCita={editCita}
            setEditCita={setEditCita}
            modalResponse={modalResponse}
            setDataModal={setDataModal}
            setModalResponse={setModalResponse}
            setModalTwoButtonsOpened={setModalTwoButtonsOpened}
            modalWaiting={modalWaiting}
            setModalWaiting={setModalWaiting}
            fromGestion={false}
            usuarioActive={usuarioActiveRef.current}
          />
        </div>
		    <ModalDeleteCita
            activeModalDeleteCita={activeModalDeleteCita}
            setActiveModalDeleteCita={setActiveModalDeleteCita}
        />
        <ModalEliminarCliente
          data={dataModalEliminar}
          setModalResponse={deleteClienteResponse}
          modalOpened={modalEliminarClienteOpened}
          setModalOpened={setModalEliminarClienteOpened}
        />
        <YlmModalTwoButtons
            data={dataModal}
            setModalResponse={setModalResponse}
            modalTwoButtonsOpened={modalTwoButtonsOpened}
            setModalTwoButtonsOpened={setModalTwoButtonsOpened}
        />
      </div>
    </>
  )
}
